/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

type TripTitleHeaderTextProps = {
  canEdit: boolean,
};

export const TripTitleHeaderText = styled.span<TripTitleHeaderTextProps>`
  font-weight: 700;
  white-space: normal;
  font-size: 2.525rem;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0.25px;
  user-select: none;

  ${(props) => {
    if (props.canEdit) {
      return '&:hover { cursor: text; }';
    }
    return '';
  }}

  &:focus {
    border-bottom: 0.15rem solid #17a8b2;
    outline: none;
  }
`;

type TripDetailsContainerProps = {
  hasTripDates: boolean,
};

export const TripDetailsContainer = styled.div<TripDetailsContainerProps>`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 5px;
`;

export const TripDatesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
`;
