import styled from 'styled-components';
import Button from '@mui/material/Button';

type MobileProp = {
  isMobile: boolean,
};

export const HomeContainer = styled.div<MobileProp>`
  height: 100vh;
  width: 100%;
  padding: ${(props) => (props.isMobile ? '0em 3em 3em 3em' : '0')};
  margin: 0;
  position: absolute;
  top: 0;
  z-index: -1;
  text-align: center;
`;

export const LogoContainer = styled.div`
  margin-top: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoImg = styled.img`
  width: 100%;
  max-width: 22rem;
  margin-bottom: 10px;
`;

export const HomeHeader = styled.h1<MobileProp>`
  font-family: Poppins;
  font-size: ${(props) => (props.isMobile ? '0.875rem' : '1.5rem')};
  font-weight: normal;
  text-transform: uppercase;
  animation: slide-right 20s linear infinite;
  color: #1b1b1b;
`;

export const HomeAutocompleteContainer = styled.div<MobileProp>`
  position: relative;
  display: ${(props) => (props.isMobile ? 'block' : 'flex')};
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.isMobile ? '' : '75em')};

  input {
    opacity: 0.9;
    text-align: ${(props) => (props.isMobile ? 'center' : 'left')};
    margin-top: ${(props) => (props.isMobile ? '20px' : '')};
  }
`;

export const HomeInputLabel = styled.span<MobileProp>`
  font-family: Poppins;
  font-size: ${(props) => (props.isMobile ? '2.25rem' : '3rem')};
  font-weight: bold;
  width: ${(props) => (props.isMobile ? '200px' : '400px')};
  margin-right: ${(props) => (props.isMobile ? '' : '-65px')};
`;

export const StartPlanningButton = styled(Button)`
  margin: 36px;
  background-color: rgba(254, 210, 101, 0.7);
  color: #000000;
  border: none;
  border-radius: 10px;
  padding: 8px 24px;

  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`;

export const HomeButtonContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

export const HomepageFooter = styled.footer<MobileProp>`
  font-size: 12px;
  width: 100%;
  margin-top: ${(props) => (props.isMobile ? '160px' : '180px')};
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`;

export const HomeErrorMessage = styled.div<MobileProp>`
  color: #eb5757;
  font-size: 0.875rem;
  position: absolute;
  bottom: -20px;
  left: ${(props) => (props.isMobile ? '' : '38px')};
  width: 100%;
`;
