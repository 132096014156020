import React, { useEffect, useMemo, useRef, useState } from 'react';
import { getDetails, getGeocode, getLatLng } from 'use-places-autocomplete';
import TripSidebar from '../trip-sidebar/trip-sidebar.component';
import TripSidebarMobile from '../trip-sidebar/trip-sidebar-mobile.component';
import PlacesAutocomplete from '../../places-autocomplete/places-autocomplete.component';
import Map from '../../map/map.component';
import useMobileMediaQuery from '../../../utils/media-query.utils';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectActiveList,
  selectActiveListCardOrder,
  selectActiveListCards,
  selectActiveListOriginalCardOrder,
  selectCards,
  selectTripLocation,
} from '../../../redux/trip/trip.selectors';
import { CardID, CurrentTrip, SnackbarMessage, TripCard } from '../../../redux/trip/trip.types';
import MobileTripListDefault from '../itinerary/itinerary-sidebar/itinerary-sidebar-default/itinerary-sidebar-default.component';
import { getMarkersForCardsInListsAndExtendBounds } from '../../map/map.utils';
import { isPlaceAlreadyInList } from '../../../redux/trip/trip.utils';
import { selectCurrentUser } from '../../../redux/user/user.selectors';
import { addCardToList } from '../../../redux/trip/trip.slice';
import { Marker } from '../../marker/marker.types';
import SortableCard from '../../sortable-card/sortable-card.component';
import Toast from '../../snackbar/snackbar.component';

import {
  TripAutocompleteContainer,
  TripCardsScrollableContainer,
  TripMapTopContainer,
  TripPlaygroundContainer,
  TripPlaygroundMapContainer,
  TripSidebarContainer,
  TripWithCardsMapContainer,
  ViewMapButton,
} from './trip-playground.styles';

function isPlaceResult(
  result: google.maps.places.PlaceResult | string
): result is google.maps.places.PlaceResult {
  return (result as google.maps.places.PlaceResult).name !== undefined;
}

interface TripPlaygroundProps {
  trip: CurrentTrip,
  canEdit?: boolean,
  handleMarkerClick: (marker: Marker) => void,
}

function TripPlayground({ trip, canEdit, handleMarkerClick }: TripPlaygroundProps) {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const isMobile = useMobileMediaQuery();
  const activeList = useAppSelector(selectActiveList);
  const activeListCards = useAppSelector(selectActiveListCards);
  const activeListCardOrder = useAppSelector(selectActiveListCardOrder);
  const activeListOriginalCardOrder = useAppSelector(selectActiveListOriginalCardOrder);
  const cards = useAppSelector(selectCards);
  const location = useAppSelector(selectTripLocation);
  const mapRef = useRef<any | undefined>(undefined);
  const newMarker = useRef(new window.google.maps.Marker());

  const [isMapVisible, setIsMapVisible] = useState<boolean>(!!canEdit);
  const [shouldFitBounds, setShouldFitBounds] = useState(true);
  const [highlightedCardId, setHighlightedCardId] = useState<CardID | null>(null);
  const [snackbarMessage, setSnackbarMessage] = useState<SnackbarMessage | null>(null);

  const orderedActiveListCards = useMemo(() => {
    if (activeListCards && activeListCardOrder) {
      return activeListCardOrder.reduce((previousValue, currentValue) => {
        const currentCard = activeListCards.find((card) => card.id === currentValue);
        if (currentCard) {
          return previousValue.concat([currentCard]);
        }
        return previousValue;
      }, [] as TripCard[]);
    }
    return [];
  }, [activeListCards, activeListCardOrder]);

  const snackbarOpen = Boolean(snackbarMessage);

  useEffect(() => {
    setShouldFitBounds(true);
  }, [activeList]);

  const activeListMapBounds = new window.google.maps.LatLngBounds();
  const { markers: activeListMarkers } =
    activeList && cards
      ? getMarkersForCardsInListsAndExtendBounds(
          [activeList],
          cards,
          activeListMapBounds,
          highlightedCardId
        )
      : { markers: [] };

  if (shouldFitBounds && mapRef?.current && activeListMarkers.length) {
    mapRef.current.fitBounds(activeListMapBounds);
    if (mapRef.current.getZoom() > 14) {
      mapRef.current.setZoom(14);
    }
    setShouldFitBounds(false);
  }

  const doesActiveListHaveCards = () => orderedActiveListCards.length > 0;

  const handleSuggestionMouseEnter = (suggestion: google.maps.places.AutocompletePrediction) => {
    if (!isMapVisible) return;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { place_id } = suggestion;
    getGeocode({ placeId: place_id })
      .then((results) => {
        const latLng = getLatLng(results[0]);
        newMarker.current.setPosition(latLng);
        newMarker.current.setMap(mapRef.current);
        if (!mapRef?.current.getBounds().contains(latLng)) {
          mapRef?.current.panTo(latLng);
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
  };

  const handleSuggestionMouseLeave = () => {
    if (!isMapVisible) return;

    newMarker.current.setMap(null);
  };

  const handleSuggestionSelect = (suggestion: google.maps.places.AutocompletePrediction) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { place_id } = suggestion;
    const fields = [
      'address_components',
      'adr_address',
      'business_status',
      'formatted_address',
      'formatted_phone_number',
      'geometry.location',
      'international_phone_number',
      'name',
      'opening_hours.weekday_text',
      'price_level',
      'rating',
      'types',
      'url',
      'website',
    ];
    newMarker.current.setMap(null);
    getDetails({ placeId: place_id, fields })
      .then((details) => {
        if (cards && activeList && isPlaceResult(details)) {
          if (isPlaceAlreadyInList(activeList, cards, place_id)) {
            setSnackbarMessage({
              severity: 'error',
              text: `${details.name} has already been added to this list!`,
            });
          } else if (currentUser && trip) {
            const card = {
              googlePlaceId: place_id,
              name: details.name,
              addressComponents: details.address_components,
              adrAddress: details.adr_address,
              businessStatus: details.business_status,
              formattedAddress: details.formatted_address,
              formattedPhoneNumber: details.formatted_phone_number,
              internationalPhoneNumber: details.international_phone_number,
              location:
                details.geometry && details.geometry.location
                  ? {
                      latitude: details.geometry.location.lat(),
                      longitude: details.geometry.location.lng(),
                    }
                  : undefined,
              openingHours: details.opening_hours ? details.opening_hours.weekday_text : undefined,
              priceLevel: details.price_level,
              types: details.types,
              rating: details.rating,
              url: details.url,
              website: details.website,
              creator: currentUser.uid,
            };
            dispatch(
              addCardToList({
                tripId: trip.id,
                listId: activeList.id,
                card,
                cardOrder: activeListCardOrder || [],
              })
            );
          }
        }
      })
      .catch((error) => {
        console.log('Error ', error);
      });
  };

  const handleCardMouseEnter = (card: TripCard) => {
    if (!isMapVisible || !card.location) return;

    const { latitude, longitude } = card.location;
    const latLng = { lat: latitude, lng: longitude };
    if (mapRef?.current && !mapRef.current.getBounds().contains(latLng)) {
      mapRef.current.panTo(latLng);
    }
    setHighlightedCardId(card.id);
  };

  const handleCardMouseLeave = () => {
    if (!isMapVisible) return;

    setHighlightedCardId(null);
  };

  return (
    <>
      <TripPlaygroundContainer>
        <TripSidebarContainer>
          {trip && isMobile ? (
            <>
              <TripSidebarMobile
                trip={trip}
                canEdit={canEdit}
                location={location}
                onSuggestionMouseEnter={handleSuggestionMouseEnter}
                onSuggestionMouseLeave={handleSuggestionMouseLeave}
                onSuggestionSelect={handleSuggestionSelect}
              />
              {trip.lists.length === 0 && <MobileTripListDefault />}
            </>
          ) : (
            <TripSidebar />
          )}
        </TripSidebarContainer>
        {canEdit && !isMobile && (
          <TripMapTopContainer
            isMapVisible={isMapVisible}
            hasCards={doesActiveListHaveCards()}
            canEdit={canEdit}
          >
            {trip.lists.length > 0 && (
              <TripAutocompleteContainer>
                <PlacesAutocomplete
                  location={location}
                  placeholder="Add a place or an address"
                  className="playground-autocomplete"
                  onSuggestionMouseEnter={handleSuggestionMouseEnter}
                  onSuggestionMouseLeave={handleSuggestionMouseLeave}
                  onSuggestionSelect={handleSuggestionSelect}
                  shouldClearValueOnSelect
                />
              </TripAutocompleteContainer>
            )}
            {doesActiveListHaveCards() && (
              <ViewMapButton variant="outlined" onClick={() => setIsMapVisible(!isMapVisible)}>
                {isMapVisible ? 'Close Map' : 'View Map'}
              </ViewMapButton>
            )}
          </TripMapTopContainer>
        )}
        {activeList && !isMobile && doesActiveListHaveCards() ? (
          <>
            <TripCardsScrollableContainer isMapVisible={isMapVisible} canEdit={!!canEdit}>
              {orderedActiveListCards.map((card) => (
                <SortableCard
                  key={card.id}
                  card={card}
                  listId={activeList.id}
                  tripId={trip.id}
                  cardOrder={activeListCardOrder || []}
                  originalCardOrder={activeListOriginalCardOrder}
                  canEdit={canEdit}
                  canDelete={canEdit}
                  onCardMouseEnter={handleCardMouseEnter}
                  onCardMouseLeave={handleCardMouseLeave}
                  isLarge={!isMapVisible}
                />
              ))}
            </TripCardsScrollableContainer>
            {isMapVisible && !isMobile && (
              <TripWithCardsMapContainer>
                <Map
                  ref={mapRef}
                  center={location}
                  markers={activeListMarkers}
                  markerOnClick={handleMarkerClick}
                />
              </TripWithCardsMapContainer>
            )}
          </>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {!isMobile && (
              <TripPlaygroundMapContainer>
                <Map ref={mapRef} center={location} />
              </TripPlaygroundMapContainer>
            )}
          </>
        )}
      </TripPlaygroundContainer>

      {snackbarOpen && snackbarMessage && <Toast message={snackbarMessage} setSnackbarMessage={setSnackbarMessage} />}
    </>
  );
}

TripPlayground.displayName = 'TripPlayground';
export default TripPlayground;
