/* eslint-disable camelcase */
/**
 *
 * Trip Page
 *
 */
import React, { SyntheticEvent, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Tab } from '@mui/material';
import { TabContext } from '@mui/lab';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { debounce, isEqual } from 'lodash';
import { Descendant } from 'slate';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

import {
  selectCards,
  selectCurrentTrip,
  selectError,
  selectMapCenter,
  selectMapZoom,
  selectTripLocation,
} from '../../redux/trip/trip.selectors';
import {
  updateMapCenter,
  updateMapZoom,
  clearCurrentTrip,
  fetchTrip,
  updateTrip,
} from '../../redux/trip/trip.slice';
import useMobileMediaQuery from '../../utils/media-query.utils';
import { canEditTrip } from '../../redux/trip/trip.utils';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { Marker } from '../marker/marker.types';
import { TripProvider } from '../../contexts/trip.context';
import { isNotesEmpty } from '../../utils/slate.utils';

import Spinner from '../spinner/spinner.component';
import RichTextEditor from '../rich-text-editor/rich-text-editor.component';
import CardDetailsModal from '../card-details-modal/card-details-modal.component';
import SingleCard from '../card/single-card.component';
import TripShare from './trip-share/trip-share.component';
import Itinerary from './itinerary/itinerary.component';
import TripMap from './trip-map/trip-map.component';
import TripPlayground from './trip-playground/trip-playground.component';
import TripTitleHeader from './trip-title-header/trip-title-header.component';

import {
  TripContainer,
  TripHeaderContainer,
  TripMainContainer,
  TripTabList,
  TripTabPanel,
  TripNotesContainer,
} from './trip.styles';

function Trip() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useMobileMediaQuery();
  const { tripId } = useParams();
  const tripError = useAppSelector(selectError);
  const currentUser = useAppSelector(selectCurrentUser);
  const currentTrip = useAppSelector(selectCurrentTrip);
  const cards = useAppSelector(selectCards);
  const location = useAppSelector(selectTripLocation);
  const mapCenter = useAppSelector(selectMapCenter);
  const mapZoom = useAppSelector(selectMapZoom);

  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get('tab') || 'playground';
  const cardDetailToDisplayId = searchParams.get('card') || null;

  const canEdit = Boolean(canEditTrip(currentTrip, currentUser));
  const notes = currentTrip ? currentTrip.notes : null;
  const isLoading = !currentTrip;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSaveNotes = useCallback(
    debounce((newNotes) => {
      if (currentTrip && tripId && !isEqual(newNotes, currentTrip.notes)) {
        dispatch(updateTrip({ tripId, field: 'notes', value: newNotes }));
      }
    }, 500),
    [currentTrip]
  );

  useEffect(() => {
    if (tripId) {
      sessionStorage.removeItem('selectedLists');
      dispatch(fetchTrip({ tripID: tripId, userID: currentUser?.uid }));
    } else {
      navigate('/');
    }
  }, [dispatch, navigate, tripId, currentUser]);

  // Handle trip error
  useEffect(() => {
    if (tripError) {
      navigate('/forbidden');
    }
  }, [navigate, tripError]);

  // Clear out currentTrip when unmounting
  useEffect(
    () => () => {
      dispatch(clearCurrentTrip());
    },
    [dispatch]
  );

  const handleTabChange = useCallback(
    (event: SyntheticEvent, newValue: string) => {
      searchParams.set('tab', newValue);
      searchParams.delete('list');
      searchParams.delete('card');
      searchParams.delete('card-tab');
      searchParams.delete('photos');
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const handleMarkerClick = useCallback(
    (marker: Marker) => {
      searchParams.set('card', marker.id);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMapCenterChange = useCallback(
    debounce((lat, lng) => {
      if (currentTrip) {
        dispatch(updateMapCenter({ lat, lng }));
      }
    }, 1000),
    [currentTrip]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMapZoomChange = useCallback(
    debounce((zoom) => {
      if (currentTrip) {
        dispatch(updateMapZoom(zoom));
      }
    }, 1000),
    [currentTrip]
  );

  const handleCardDetailsClose = useCallback(() => {
    searchParams.delete('card');
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  return isLoading || !currentTrip ? (
    <Spinner />
  ) : (
    <>
      <Helmet>
        <title>
          Wander | {currentTrip.title} | {currentTab.charAt(0).toUpperCase() + currentTab.slice(1)}
        </title>
      </Helmet>
      <TripContainer isMobile={isMobile}>
        <TripHeaderContainer isMobile={isMobile}>
          <TripTitleHeader trip={currentTrip} canEdit={canEdit} currentUser={currentUser} />
          {!isMobile && <TripShare trip={currentTrip} canEdit={canEdit} />}
        </TripHeaderContainer>

        <DndProvider backend={HTML5Backend}>
          <TripProvider>
            <TripMainContainer>
              <TabContext value={currentTab}>
                <TripTabList onChange={handleTabChange}>
                  <Tab label="Playground" value="playground" />
                  <Tab label="Map" value="map" />
                  {(canEdit || Object.keys(currentTrip.events).length > 0) && (
                    <Tab label="Itinerary" value="itinerary" />
                  )}
                  {(canEdit || !isNotesEmpty(currentTrip)) && (
                    <Tab label="Notepad" value="notepad" />
                  )}
                </TripTabList>

                <TripTabPanel value="playground">
                  <TripPlayground
                    trip={currentTrip}
                    canEdit={canEdit}
                    handleMarkerClick={handleMarkerClick}
                  />
                </TripTabPanel>
                <TripTabPanel value="map">
                  <TripMap
                    center={mapCenter || location}
                    zoom={mapZoom}
                    cards={currentTrip.cards}
                    lists={currentTrip.lists}
                    listOrder={currentTrip.listOrder}
                    markerOnClick={handleMarkerClick}
                    onCenterChanged={handleMapCenterChange}
                    onZoomChanged={handleMapZoomChange}
                    fitBounds={!mapCenter || !mapZoom}
                  />
                </TripTabPanel>
                {(canEdit || Object.keys(currentTrip.events).length > 0) && (
                  <TripTabPanel value="itinerary">
                    <Itinerary trip={currentTrip} />
                  </TripTabPanel>
                )}
                {(canEdit || !isNotesEmpty(currentTrip)) && (
                  <TripTabPanel value="notepad">
                    <TripNotesContainer toolbar={canEdit}>
                      <RichTextEditor
                        initialValue={notes}
                        onChange={(newNotes: Descendant[]) => {
                          debouncedSaveNotes(newNotes);
                        }}
                        toolbar={canEdit}
                        placeholder={
                          canEdit
                            ? 'A space to add misc. notes, like a packing list, or other trip details...'
                            : null
                        }
                        readOnly={!canEdit}
                      />
                    </TripNotesContainer>
                  </TripTabPanel>
                )}
              </TabContext>
            </TripMainContainer>
          </TripProvider>
        </DndProvider>
      </TripContainer>

      {cardDetailToDisplayId &&
        tripId &&
        cards &&
        cards[cardDetailToDisplayId] &&
        currentTab === 'map' &&
        (isMobile ? (
          <SingleCard
            card={cards[cardDetailToDisplayId]}
            tripId={tripId}
            handleClose={handleCardDetailsClose}
          />
        ) : (
          <CardDetailsModal
            card={cards[cardDetailToDisplayId]}
            tripId={tripId}
            canEdit={canEdit}
            open={cardDetailToDisplayId !== null}
            handleClose={handleCardDetailsClose}
          />
        ))}
    </>
  );
}

Trip.displayName = 'TripPage';
export default Trip;
