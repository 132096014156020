import styled from 'styled-components';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Fab,
} from '@mui/material';

type MobileProp = {
  isMobile: boolean,
};

export const CreateTripContainer = styled.div`
  position: relative;
  bottom: 55px;
  right: 70px;
`;

export const StyledDialog = styled(Dialog)``;

export const DialogTitleContainer = styled.div`
  overflow: hidden;
`;

export const DialogSubtitle = styled(DialogTitle)<MobileProp>`
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
  margin-top: ${(props) => (props.isMobile ? '120' : '68')}px;
  margin-right: ${(props) => (props.isMobile ? '40px' : '')};
  margin-bottom: -40px;
`;

export const DialogMainTitle = styled(DialogTitle)<MobileProp>`
  font-weight: bold;
  font-size: ${(props) => (props.isMobile ? '42' : '44')}px;
  margin-right: ${(props) => (props.isMobile ? '62px' : '')};
  text-align: center;
`;

export const StyledDialogContent = styled(DialogContent)<MobileProp>`
  width: ${(props) => (props.isMobile ? '65' : '90')}%;
  margin: ${(props) => (props.isMobile ? '0 0 0 -64px' : '40px 12px 0 28px')};
  align-self: center;
  overflow: visible;
`;

export const StyledDialogActions = styled(DialogActions)<MobileProp>`
  justify-content: ${(props) => (props.isMobile ? 'end' : 'center')};
  margin-right: ${(props) => (props.isMobile ? '160px' : '')};
  margin-bottom: ${(props) => (props.isMobile ? '110px' : '')};
`;

export const CreateTripButton = styled(Button)<MobileProp>`
  border-radius: 10px;
  padding: 12px 32px;
  background-color: #ffd643;
  text-transform: none;
  margin-bottom: ${(props) => (props.isMobile ? '20' : '90')}px;
`;

type ModalToggleButtonProps = {
  modalOpen: boolean,
};
export const ToggleModalButton = styled(Fab)<MobileProp & ModalToggleButtonProps>`
  background-color: #ffd643;
  box-shadow: 0px 6px 8px rgb(0 0 0 / 45%);
  right: ${(props) => (props.isMobile ? '25' : '80')}px;
  bottom: ${(props) => (props.isMobile ? '40' : '90')}px;
  z-index: ${(props) => (props.modalOpen ? '999' : '1')};
  position: fixed;
  cursor: pointer;

  &:hover {
    background-color: white;
  }
`;

export const CustomOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 998; /* Ensure overlay is above other content */
`;
